import React, { useContext, useState } from "react";
import { SIGN_UP_PROCESS_STEP } from "../../../commons/signup.constants";
import DataLoadingContext from "../../../context/data-loading-context";
import SignupContext from "../../../context/sign-up-context";
import SignUpSliderItem from "./sign-up-slider-item";

const SignupSetBuyingGoal = () => {
  const { setSignupProcess } = useContext(SignupContext);
  const { setAppState } = useContext(DataLoadingContext);
  const [addingScenario, setAddingScenario] = useState(false);
  const [addingScenarioStatus, setAddingScenarioStatus] = useState({
    status: false,
    message: "",
  });
  const { params } = useContext(SignupContext);
  const { firstName = "" } = params;

  const setBuyingGoal = (sliderValue) => {
    setAppState({
      pendingToCreateScenarios: [
        {
          buyingGoal: sliderValue,
          firstName,
        },
      ],
    });
    setAddingScenarioStatus({
      status: true,
      message: "Set buying goal successfully",
    });
    setTimeout(() => {
      setSignupProcess(SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS);
    }, 3000);
    setAddingScenario(false);
  };

  return (
    <div className="body-context-container">
      <span className="signup-context-title">
        How much do you want to <br />
        spend on a property?
      </span>
      <div className="signup-slider-container">
        <SignUpSliderItem
          setBuyingGoal={setBuyingGoal}
          addingScenario={addingScenario}
          addingScenarioStatus={addingScenarioStatus}
        />
      </div>
    </div>
  );
};

export default SignupSetBuyingGoal;
